// Notes about import maps. You can import 3 ways:
// Absolute path:
// import React from "/Users/DHH/projects/basecamp/node_modules/react"
// Relative path:
// import React from "./node_modules/react"
// HTTP path:
// import React from "https://ga.jspm.io/npm:react@17.0.1/index.js"
// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

// pins like "@hotwired/turbo-rails" are defined in config -> importmap.rb
// NOTE importmaps is just for js NOT css

import "@hotwired/turbo-rails"
import "jquery"

import "@popperjs/core"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
window.bootstrap = bootstrap;

import "./plugins/sb-admin-2"
import "./controllers"

import Sortable from "./plugins/sortable.min";
// Expose Sortable globally
window.Sortable = Sortable;


// import "./plugins/select2_4_0_13.min"
// import "./plugins/select2_4_0_13_ru.min"

// using my own version of moment

// add the string.format method
if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}

// function that tries to get a param from the query string
window.getParameterByName = function(name, url) {
   if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// load on each page load
$(document).off('turbo:load').on('turbo:load', function() {

  $(document).off('click', '.simple-collapse').on("click", ".simple-collapse", function(e){
    // toggle the collapse class on this
    $(this).toggleClass('collapsed');
    // toggle the show on all the aria-controls
    $('.' + $(this).attr('aria-controls')).toggleClass("hide");    
  });

  $(document).off('click', '.print-modal').on('click', '.print-modal', function(e){
    var printContents = document.querySelector('.modal-dialog').innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    
    // Reload Bootstrap scripts to restore modal functionality after printing
    setTimeout(function() {
      location.reload();
    }, 1000);
  });
  
  // This opens select2 pickers on focus
  $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
    $(this).closest(".select2-container").siblings('select:enabled').select2('open');
  });
  // This opens select2 pickers on focus
  // steal focus during close - only capture once and stop propogation
  $('select.select2').on('select2:closing', function (e) {
    $(e.target).data("select2").$selection.one('focus focusin', function (e) {
      e.stopPropagation();
    });
  });

  $(document).off('click', '.close-modal').on('click', '.close-modal', function(e){
    let m = $(this).parents('.modal');
    if(modal){ m.modal('hide')}
  });
  

  // if they ever change the current business
  $(document).off('change', '#selected_business').on('change', '#selected_business', function(e){ 
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('set_current_business', $(this).val());
    window.location.search = urlParams;
  });

  $(document).off('click', '.save-and-add-btn').on('click', '.save-and-add-btn', function(e){
  	
  	$(this).parents('form').find('#save_add_another').val(true);
  	$(this).parents('form').submit();
  });

  // Dynamic nested form code
  $(document).off('click', '.remove_fields').on('click', '.remove_fields', function(e){

    if($(this).hasClass('confirm-remove')){
      if(!confirm("DELETE this record?")){
        return e.preventDefault();    
      }
    }

    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('.nested_form_row').addClass('removed').hide();
    $(this).trigger("after_remove_fields_click");
    return e.preventDefault();
  });

  // Dynamic nested form code
  $(document).off('click', '.add_fields').on('click', '.add_fields', function(e){
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');    

    var add_before_el = $(this);
    if($(this).data('add-before'))
      add_before_el = $('#' + $(this).data('add-before'))
    
    add_before_el.before($(this).data('fields').replace(regexp, time));
    
    // $(this).before($(this).data('fields').replace(regexp, time));
    
    $(this).trigger("after_add_fields_click");

    return e.preventDefault();
  });

  if(getParameterByName('show_tab')){
    $('.nav-pills button[id="{0}"]'.format(getParameterByName('show_tab'))).tab('show');
  }
  
  // Now run the js on each individual page
  const myEvent = new Event('on_individual_page_js');
  document.dispatchEvent(myEvent);
}) //End turbo:load

